import React, {Component} from 'react';
import './index.css';

class Icon extends Component {
  constructor(props) {
    super(props);
    this.state = {
       icon: this.props.icon ? this.props.icon : "default",
       url: this.props.url ? this.props.url : "",
       download: this.props.download ? true: false
    };
  }
  render(){
    return (
      <a id={this.state.icon} className="clickable icon" href={this.state.url} download={this.state.download}>{this.props.children}</a>
    );
  }
}

export default Icon;
