import React, {Component} from 'react';
import './index.css';

class Paragraph extends Component {
  render(){
    return (
      <div className="paragraph">{this.props.children}</div>
    );
  }
}

export default Paragraph;
