import React, {Component} from 'react';
import './index.css';

class Stack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      direction: this.props.row ? " row" : " column",
      position: this.props.center ? " center" : "",
      size: this.props.fullsize ? " fullsize" : this.props.halfwidth ? " halfwidth" : "",
      padding: this.props.nopadding ? " nopadding" : ""
    };
  }
  render(){
    return (
      <div className={"stack" + this.state.direction + this.state.position + this.state.size + this.state.padding}>{this.props.children}</div>
    );
  }
}

export default Stack;
