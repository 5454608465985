import React, { Component } from 'react';
import './App.css';

import Button from "./components/button";
import Icon from "./components/icon";
import Stack from "./components/stack";
import Paragraph from "./components/paragraph";
import profilpic from "./img/profilpicsaturated.png";

class App extends Component {
  render() {
    return (
      <Stack row fullsize>
        <Stack row center halfwidth>
          <Stack column center>
            <Paragraph><h1>Hi I'm Louis Dauvergne,</h1><p> freelancer in Paris,<br /> but above all,</p><h2>I design things</h2></Paragraph>
            <Stack row>
              <Button label="Hire Me" url="https://www.linkedin.com/in/louis-dauvergne-7b73b8a4/" />
              <Button label="Discover My Work" color="secondary" url="https://www.behance.net/louisdauvergne" />
            </Stack>
            <Stack row>
              <Icon icon="linkedin" url="https://www.linkedin.com/in/louis-dauvergne-7b73b8a4/" />
              <Icon icon="behance" url="https://www.behance.net/louisdauvergne" />
              <Icon icon="cv" url="./documents/cv Louis DAUVERGNE Designer.pdf" download />
              <Icon icon="contact" url="mailto:iamlouisdauvergne@gmail.com" />
            </Stack>
          </Stack>
        </Stack>
        <Stack halfwidth nopadding>
          <img src={profilpic} alt="Louis Dauvergne - Designer"/>
        </Stack>
      </Stack>
    );
  }r
}

export default App;
